import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "../components/headers/header";
import Ariane from "../components/ariane";
import Footer from "../components/footers/footer";
import Sidebar from "../components/sidebars/sidebarGeo";

////Intégration Shortcodes
import CTAExpertPresChezVous from "../components/shortcodes/CTAExpertPresChezVous";
import CTAExpertAvisIndependant from "../components/shortcodes/CTAExpertAvisIndependant";
import Image from "../components/shortcodes/Image";
import Youtube from "../components/shortcodes/youtube";
import CTAKitFissuration from "../components/shortcodes/CTAKitFissuration";

// CTA Expert
import CTAOpengroupe from "../components/shortcodes/cta/CTAOpengroupe";
import CTAEdieuxExpertise from "../components/shortcodes/cta/CTAEdieuxExpertise";
import CTADuboisExpertise from "../components/shortcodes/cta/CTADuboisExpertise";
import CTACabinetE2PPloixExpertise from "../components/shortcodes/cta/CTACabinetE2PPloixExpertise";
import CTALunoaExpertConseilBenamar from "../components/shortcodes/cta/CTALunoaExpertConseilBenamar";
import CTAAvayahJeromeGallaud from "../components/shortcodes/cta/CTAAvayahJeromeGallaud";
import CTACabinetLespagnol from "../components/shortcodes/cta/CTACabinetLespagnol";
import CTAEGExpabatRoux from "../components/shortcodes/cta/CTAEGExpabatRoux";
import CTAVincentMoreau from "../components/shortcodes/cta/CTAVincentMoreau";
import CTABatExpert34 from "../components/shortcodes/cta/CTABatExpert34";
import CTABenoitToussaint from "../components/shortcodes/cta/CTABenoitToussaint";
import CTAMathieuMaillardGers from "../components/shortcodes/cta/CTAMathieuMaillardGers";
import CTANouryExpertises from "../components/shortcodes/cta/CTANouryExpertises";
import CTA1GSolutions from "../components/shortcodes/cta/CTA1GSolutions";
import CTA2RExpertise from "../components/shortcodes/cta/CTA2RExpertise";
import CTARenfortSolutions from "../components/shortcodes/cta/CTARenfortSolutions";
import CTAGeoStudy from "../components/shortcodes/cta/CTAGeoStudy";

const shortcodes = {
  Youtube,
  Image,
  CTAOpengroupe,
  CTAExpertPresChezVous,
  CTAExpertAvisIndependant,
  CTAOpengroupe,
  CTAEdieuxExpertise,
  CTADuboisExpertise,
  CTACabinetE2PPloixExpertise,
  CTALunoaExpertConseilBenamar,
  CTAAvayahJeromeGallaud,
  CTACabinetLespagnol,
  CTAEGExpabatRoux,
  CTAVincentMoreau,
  CTABatExpert34,
  CTABenoitToussaint,
  CTAMathieuMaillardGers,
  CTANouryExpertises,
  CTARenfortSolutions,
  CTAKitFissuration,
  CTAGeoStudy,
  CTA1GSolutions,
  CTA2RExpertise,
};
////

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>
      <div class="relative max-w-7xl mx-auto shadow-lg lg:shadow-none lg:flex mb-4">
        <Ariane fil_ariane={fil_ariane} />
      </div>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <div class="flex flex-1 overflow-hidden">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                <div className="relative py-4 bg-white overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                      <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>
                      {excerpt ? <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">{excerpt}</p> : null}
                      <GatsbyImage
                        alt={thumbnailText}
                        image={getImage(embeddedImagesLocal[0])}
                      />
                    </div>

                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start sticky top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
          <Sidebar />
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;

/*

export default function pageTemplate({
  data,
}) {
  //let post = data.markdownRemark
  //let featuredImgFluid = post.frontmatter.thumbnail.childImageSharp.fluid

  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  //console.log(html)
  // TODO : Mettre ici le gestionnaire de shortcode
  //

  return (
    <div className="bg-white">
      <Helmet>
        <title>{frontmatter.title_meta}</title>
        <meta name="description" content={frontmatter.description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        
          <main className="">
            <div class="flex flex-1 overflow-hidden pr-4">
              <div class="flex flex-1 flex-col">
                <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                  <div className="relative py-16 bg-white overflow-hidden">
                    <div className="relative px-4 sm:px-6 lg:px-8">
                      <div className="text-lg max-w-prose mx-auto">
                        <h1>
                          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            {frontmatter.title}
                          </span>
                        </h1>
                        <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                          {frontmatter.excerpt}
                        </p>
                        
                        
                      </div>

                      <div 
                        className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto"
                        dangerouslySetInnerHTML={{ __html: html }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>


          <aside class="self-start sticky top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
            <Sidebar />
          </aside>

        </div>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
*/
